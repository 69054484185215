import React from "react";

const Service = () => {
  return (
    <div div className="container-fluid  "  id="Service">
      {/*  <div class="container row row-cols-1 row-cols-md-3 g-4  position-relative" data-aos="fade-up">
     
<div class="col">

 
  <div class="card-body" id="Service">
       <div class=" cardposi zoom" style={{backgroundColor:'#d9f2e6'}}>
            <div class=" text-center p-1">
              <h4 class="iconed-box-title">Garden Waste </h4>
              <p class="card-text">
                Garden waste clearance can be very time consuming and not to
                mention tiresome work. Even if you decide to do it yourself,
                you’re left with waste you would like to get rid of.
                <span className="iconed-box"></span>
              </p>
              <span className="iconed-box"></span>
            </div>

            <div className="imageposi">
              <img className="imagecol" src="garden.png" alt="logo" />
            </div>
          </div>
 
</div>
</div>
<div class="col">

 
  <div class="card-body">
       <div class=" cardposi zoom" style={{backgroundColor:'#d9f2e6'}}>
        
          <div class=" text-center p-1">
           <h4 > Office Waste</h4>
            <p class="card-text">
         
              We can clear as much or as little as you need - removing
              furniture, carpet and outdated electronics, even renovation
              rubbish and overflow from your normal weekly collections.
                  <span className="iconed-box"></span>
            </p>
        
          </div>
          <div className="imageposi ">
            <img
              className=" imagecol"
              src="office.png "
             
              alt="logo"
            />
          </div>
        </div>
 
</div>
</div>
<div class="col">

 
  <div class="card-body">
        <div class="card cardposi zoom" style={{backgroundColor:'#d9f2e6'}}>
          <div class=" text-center p-1">
            
            <h4> Household Waste</h4>
            <p class="card-text">
         
              We offer professional house clearance within the London area.
              Whether you live in Soho or Croydon our service is the best
              way to clear out all the junk quickly.
               <span className="iconed-box "></span>
            </p>
             
          </div>
          
          <div className="imageposi">
            <img
              className=""
              src="https://www.claphamwasteclearance.co.uk/images/services/household.png"
             
              alt="logo"
            />
          
          </div>
           
        </div>
 
</div>
</div>

  </div> */}
      <section class="service-style1-area">
        <div class="service-style1__bg bgnext"></div>
        <div class="container">
          <div class="row ">
            <div class="col-xl-12">
              <div class="service-style1__top">
                <div class="service-style1__top-title">
                  <div class="sec-title sec-title--style2">
                    <div class="sub-title ChangeColorTyping">
                      <h1 style={{ marginTop: '-67px'}}>
                        Our &nbsp;
                        <span
                          class="typeWriter typingFont ChangeColorTyping"
                          data-checkVisible="true"
                          data-end="6000"
                          data-speed="1"
                          data-text='["Services"]'
                        ></span>
                      </h1>
                    </div>
                  </div>

                  <div class="text"></div>
                </div>
              </div>
            </div>
          </div>
       {/*   <div class="row mt-5 d-flex justify-content-around">
            <div class="col">
             <div className="">
                    <img className="" src="final2.png" alt="logo" style={{width:'50px',height:'40px'}} />
                  </div>
            </div>
            <div class="col">
             <div className=" align-item-end" style={{display:'flex',justifyContent:'end'}}>
                    <img className="" src="final2.png" alt="logo"  style={{width:'50px',height:'40px' ,display:'flex',justifyContent:'end'}}/>
                  </div>
            </div>

</div> */}

           

          <div class="row text-right-rtl  d-flex justify-content-center mt-5  " data-aos="fade-up">
          <div
          class="col-xl-3 col-lg-6 col-md-6 mt-5 wow fadeInUp animated animatnext"
          data-wow-delay="00ms"
          data-wow-duration="1500ms "
        >
          <div class="single-service-style1">
            <div class="inner">
              <div class="round-box"></div>

              <div className="imageposi">
                <img className="imagecolss" src="final000.png" alt="logo" />
              </div>
              <div class="text-center text">
                <h3>Builders Waste</h3>
                <p>
                 We can remove your construction waste where skips can be inconvenient
                </p>
              </div>
            </div>
            {/* <div class="btn-box">
                            <a href="http://fastwpdemo.com/newwp/westo/services/garbage-pickup/"><span class="icon-right-arrow"></span></a>
</div>*/}
          </div>
        </div>
            <div
              class="col-xl-3 col-lg-6 col-md-6 mt-5 wow fadeInUp animated animatnext"
              data-wow-delay="00ms"
              data-wow-duration="1500ms "
            >
              <div class="single-service-style1">
                <div class="inner">
                  <div class="round-box"></div>

                  <div className="imageposi">
                    <img className="imagecol" src="final061.png" alt="logo" />
                  </div>
                  <div class="text text-center">
                    <h3>Garden Waste</h3>
                    <p>
                      Garden waste clearance can be very time consuming and not
                      to mention tiresome work. Even if you decide to do it
                      yourself, you’re left with waste you would like to get rid
                      of.
                    </p>
                  </div>
                </div>
                {/* <div class="btn-box">
                                <a href="http://fastwpdemo.com/newwp/westo/services/garbage-pickup/"><span class="icon-right-arrow"></span></a>
</div>*/}
              </div>
            </div>

            <div
              class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp animated mt-5"
              data-wow-delay="00ms"
              data-wow-duration="1500ms animattwo"
            >
              <div class="single-service-style1">
                <div class="inner">
                  <div class="round-box"></div>
                  <div class="icon">
                    <span class="icon-dustbin-1"></span>
                  </div>
                  <div className="imageposi">
                    <img className="imagecol" src="final051.png" alt="logo" />
                  </div>
                  <div class="text text-center">
                    <h3>Office Waste</h3>
                    <p>
                      We can clear as much or as little as you need - removing
                      furniture, carpet and outdated electronics, even
                      renovation rubbish and overflow from your normal weekly
                      collections.
                    </p>
                  </div>
                </div>
                {/*  <div class="btn-box">
                                <a href="http://fastwpdemo.com/newwp/westo/services/dumpster-rental/"><span class="icon-right-arrow"></span></a>
</div>*/}
              </div>
            </div>

            <div
            class="col-xl-3 col-lg-6 col-md-6 mt-5 wow fadeInUp animated animatnext "
            data-wow-delay="00ms"
            data-wow-duration="1500ms "
          >
            <div class="single-service-style1">
              <div class="inner">
                <div class="round-box"></div>

                <div className="imageposi">
                  <img className="imagecol" src="final0.png" alt="logo" />
                </div>
                <div class="text text-center">
                  <h3>Household Waste</h3>
                  <p>
                  We offer professional house clearance within our covered area.
                  Our service is the best way to clear out unwanted items
                  effortlessly
                  </p>
                </div>
              </div>
              {/* <div class="btn-box">
                              <a href="http://fastwpdemo.com/newwp/westo/services/garbage-pickup/"><span class="icon-right-arrow"></span></a>
</div>*/}
            </div>
          </div>

            <div
              class="col-xl-3 col-lg-6 col-md-6 mt-5 wow fadeInUp animated"
              data-wow-delay="00ms"
              data-wow-duration="1500ms animattwo"
            >
              <div class="single-service-style1">
                <div class="inner">
                  <div class="round-box"></div>
                  <div class="icon">
                    <span class="icon-dustbin-1"></span>
                  </div>
                  <div className="imageposi">
                    <img className="imagecols" src="final111.png" alt="logo" />
                  </div>
                  <div class="text text-center">
                    <h3>Fly Tipped Waste</h3>
                    <p>
                    Unfortunately fly tipping is a huge problem. We remove a lot of 
fly tipped waste for managing agents & landlords.
                    </p>
                  </div>
                </div>
                {/*  <div class="btn-box">
                                <a href="http://fastwpdemo.com/newwp/westo/services/dumpster-rental/"><span class="icon-right-arrow"></span></a>
</div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Service;
