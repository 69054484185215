import React from "react";
import { Link } from "react-router-dom";


const Footers = () => {
  return (
    <>
    

<footer class="text-center text-white footercolor footerlast " >

  <div class="container p-3 mt-3  ">
 
    <section class="">
   
      <div class="row d-flex justify-content-center">
      
      <div className="col-lg-4 col-md-4 col-sm-12 ">
            <div className="text-start " style={{marginLeft:'19px'}}>
              <img
                className="logo "
                src="logoa.png"
                width="66%"
                height="auto"
                alt="show"
              />
            </div>
            <div className=" mt-3 "style={{marginLeft:'-15px'}}>
            <div class=" widgets text-start">
              <ul>
                <li
                  id="custom_html-3"
                  class=" textwidge "
                >

                  <li className="widgetsnew"><span className="textwidget custom-html-widget widgets " > Enviro (London) Ltd</span></li>
                  <li  className="widgetsnew"> <span className="textwidget custom-html-widget widgets "  > Unit 70</span></li>
                  <li  className="widgetsnew">  <span className="textwidget custom-html-widget widgets "  >Greenway Business Centre </span></li>
                  <li  className="widgetsnew">  <span className="textwidget custom-html-widget widgets "  > Greenway </span></li>
                  <li  className="widgetsnew" > <span className="textwidget custom-html-widget widgets "  > Harlow </span></li>
                  <li className="widgetsnew" >  <span className="textwidget custom-html-widget widgets "  > Essex </span></li>
                  <li  className="widgetsnew">  <span className="textwidget custom-html-widget widgets "  > CM19 5QE</span></li>
               
                </li>
              </ul>
            </div>
          </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
            <div class=" widgets text-start">
              <ul>
                <li
                  id="custom_html-3"
                  class="widget_text widget widget_custom_html"
                >
                  <h6 class="title widget-title widgetss mb-3">London</h6>
                  <div class="textwidget custom-html-widget widgets ">
                    Opening hours ,
                    <br class="vc_hidden-xs widgets" /> Mon - Fri 08:00am to 05:00pm
                    <br /> Sat - 08:00am to 01:00pm
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 mt-4">
            <div class=" widgets text-start">
              <ul>
                <li
                  id="custom_html-6"
                  class="widget_text widget widget_custom_html"
                >
                  <h6 class="title widget-title widgetss mb-3">Contact info</h6>
                  <div class="textwidget custom-html-widget widgets">
                  Telephone number:
                    <Link className="widgets" to="tel: 01279 456695"> 01279 456695</Link>
                    {/* <br /> Landline:
                    <Link className="widgets" to="tel: 02071646439"> 02071646439</Link> */}
                    {/* <br /> WhatsApp:
                    <Link className="widgets" to="https://api.whatsapp.com/send?phone=+447717337732">
                    &nbsp;44 7717 337732 
                    </Link> */}
                    {/* <br /> Email:
                    <Link className="widgets" to="mailto:info@envirolondon.co.uk">
                      info@envirolondon.co.uk
                    </Link> */}
                  </div>
                </li>
              </ul>
            </div>
          </div>
      </div>
    </section>
  
  </div>

<hr/>
  
  <div class="text-center widgets p-1 my-2 copyright">
  © 2022 Enviro (London) Ltd. made by <span> <a class="text-white" href="https://webapp.world/"> <strong style={{color:'rgb(14, 141, 67)'}}> WebApp</strong></a></span> 
   
    
  </div>
  

</footer>

     
    </>
  );
}

export default Footers;
