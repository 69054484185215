import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import ScrollIndicator from "./ScrollIndicator";
import Typical from "react-typical";

const Home = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => console.log(data);

  const [inputFile, setInputFile] = useState(null);
  // useEffect(() => {

  // }, []);

  const handleUpload = () => {
    inputFile?.click();
    setInputFile(document.getElementById("input-file"));
  };

  return (
    <div id="home" className="pt-3">
      <div className="pt-5">
        <span></span>
        <div className="container py-2">
          <div className="row  ">
            <div
              className="col-lg-6  col-xl-6 col-md-12 col-sm-12 pt-5"
              data-aos="fade-right"
            >
              <p></p>

              <h1>
                Enviro London Waste
                <br />
                <span
                  class="typeWriter typingFont"
                  data-checkVisible="true"
                  data-end="6000"
                  data-speed="1"
                  data-text='["Clearance"]'
                ></span>
              </h1>

              <p className="mt-5">
                We provide a quick & surrounding areas, competitively priced and convenient waste
                collection service covering all over London.
                <br class="vc_hidden-xs" /> <br /> To get started, please fill
                out the form or give us a quick call.
              </p>
              <div class="col t-box d-flex justify-content-between">
                <p class="sr">
                  <b>Opening hours</b>
                  <br /> Mon - Fri 08:00am to 05:00pm
                  <br />
                  Sat - 08:00am to 01:00pm
                </p>
                <p class="col-4 sr contact">
                  <b>Contact Info</b>
                  <br />
                  Telephone number:
                  <a
                    style={{ textDecoration: "none", color: "rgb(26, 87, 54)" }}
                    href="tel:01279 456695"
                  >
                  <span> 01279 456695 </span> 
                  </a>
                  {/* <br /> Landline:
                  <a
                    style={{ textDecoration: "none", color: "rgb(26, 87, 54)" }}
                    href="tel: 02071646439"
                  >    
              <span> 02071646439</span>
                  </a> */}
                  {/* <br />
                Email:
                <a
                  style={{ textDecoration: "none", color: "rgb(26, 87, 54)" }}
                  href="mailto:info@envirolondon.co.uk"
                >
                  <br /> info@envirolondon.co.uk
                </a> */}
                </p>
              </div>
            </div>
            {/* ============================form-============================== */}

            <div
              className="col-md-12 col-lg-6 col-sm-12 col-xl-6  shadow  text-center py-3 rounded"
              data-aos="fade-left   "
            >
              <span class="sq"></span>

              <form
                className=" form-horizontal"
                action="https://formspree.io/f/mpzbkqjk"
                method="post"
              >
                <div className="row  ">
                  <div className="col-lg-6 col-xl-6  col-sm-12 my-3 form-group ">
                    <input
                      type="text"
                      className="form-control bordersize "
                      placeholder="Your name"
                      name="YOUR_NAME"
                      {...register("YOUR_NAME", { required: true })}
                    />
                    {errors.YOUR_NAME && (
                      <span className="star">This field is required</span>
                    )}
                  </div>
                  <div className="col-lg-6 col-xl-6  col-sm-12 my-3 form-group">
                    <input
                      type="email"
                      className="form-control bordersize"
                      placeholder="You@youremail.com"
                      name="YOUR_EMAIL"
                      {...register("YOUR_EMAIL", { required: true })}
                    />
                    {errors.YOUR_EMAIL && (
                      <span className="star">This field is required</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-xl-6  col-sm-12  my-3 form-group">
                    <input
                      type="text"
                      className="form-control bordersize"
                      id="inputAddress"
                      placeholder="Your Phone Number"
                      name="PHONE_NUMBER"
                      {...register("PHONE_NUMBER", { required: true })}
                    />
                    {errors.PHONE_NUMBER && (
                      <span className="star">This field is required</span>
                    )}
                  </div>
                  <div className="col-lg-6 col-xl-6  col-sm-12  my-3 form-group">
                    <input
                      type="text"
                      className="form-control bordersize"
                      id="inputAddress2"
                      placeholder="Post code"
                      name="POST_CODE"
                      {...register("POST_CODE", { required: true })}
                    />
                    {errors.POST_CODE && (
                      <span className="star">This field is required</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control bordersize"
                    id="exampleFormControlTextarea1"
                    placeholder="Your Message"
                    rows="4"
                    name="MESSAGE"
                    {...register("MESSAGE", { required: true })}
                  />
                  {errors.MESSAGE && (
                    <span className="star">This field is required</span>
                  )}
                </div>

                {/* ======================================button-====================================== */}
                <div className="row d-flex   justify-content-between ">
                  <div className="  col-lg-6 col-md-12  d-md-block  d-sm-block col-sm-12 d-sm-block ">
                    <input
                      id="input-file"
                      className="d-none"
                      type="file"
                      name="UPLOAD_FILE"
                      {...register("UPLOAD_FILE", { required: true })}
                    />

                    <p
                      onClick={handleUpload}
                      className="btpp   d-md-block  d-sm-block   bttonb btn btn-outline-danger  btn-lg"
                    >
                      Upload a file
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <button
                      type="submit"
                      class="btpp   bttonb btn btn-success btn-lg d-md-block  d-sm-block "
                    >
                      Send details
                    </button>
                  </div>
                  <ToastContainer />
                </div>
              </form>
            </div>
          </div>

          {/*   <span className="scrolltext"> scroll to top</span>
      <div className="d-flex justify-content-end">
        <span className="scrolltexts"> 
   
      
     
        <a   href="https://wa.me/7900723559"  target="_blank"   rel="noreferrer"  style={{textDecoration:'none' }}>Contact Us – Ph. / whatsapp</a>
     
        </span>
       <ScrollIndicator />  */}
        </div>
      </div>
    </div>
  );
};

export default Home;
