import React from "react";
// import Nav from "./component/Nav";
import Navbar from './Components/Navbar';
import Home from "./Components/Home";
import About from "./Components/About";
import Service from "./Components/Service";
import Prices from "./Components/Prices";
import Boroughs from "./Components/Boroughs";
import Priceestimate from './Components/Priceestimate';
// import Contact from "./component/Contact";
import ScrollToTop from "./Components/ScrollToTop";
 import Footers from "./Components/Footers";
import { BrowserRouter } from "react-router-dom";
import NavBar1 from "./Components/NavBar1";
import Map from "./Components/Map";
import Scrollmap from "./Components/Scrollmap";



import './App.css';

function App() {
  return (
    <div>

    <BrowserRouter>
        
    <ScrollToTop />
    
              <NavBar1/>
         
           <Home/>
     <About/>
     <Service />
     <Prices />
     <Boroughs />
     {/* <Scrollmap/>  */}
     <Map/>

        {/* <Routes>
          {/* <ScrollToTop/> */}
          {/* <Route exact path="/"   element={<Home />} />
          <Route exact path="/About"   element={<About />} />
        
          <Route exact path="/Service" element={<Service />} />
          <Route exact path="/Prices" element={<Prices />} />
          <Route exact path="/Boroughs" element={<Boroughs />} />
      

           {/* </Routes>  */}
       
        {/* </Routes>  */} 
        <Priceestimate/>
        <Footers/>
      </BrowserRouter>
    </div>
  );
}

export default App;

