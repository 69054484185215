import { useForm } from "react-hook-form";
import React, { useState } from "react";

import { ToastContainer } from "react-toastify";
import { FiSettings } from "react-icons/fi";
// import { IoMdCube } from "react-icons/io";
import { RiDeleteBin5Fill } from "react-icons/ri";
// import { GiSwapBag } from "react-icons/gi";

const Prices = () => {
  const notify = () => {
    // toast("We Need Your Name", {
    //   position: "bottom-right",
    //   autoClose: 2000,
    //   color: "fff",
    // });
    // toast("We Need Your Email", {
    //   position: "bottom-right",
    //   autoClose: 2500,
    // });
    // toast("We Need Your Phone", {
    //   position: "bottom-right",
    //   autoClose: 3000,
    // });
    // toast("We Need Your Message", {
    //   position: "bottom-right",
    //   autoClose: 3000,
    // });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => console.log(data);

  const [inputFile, setInputFile] = useState(null);
  // useEffect(() => {

  // }, []);

  const handleUpload = () => {
    inputFile?.click();
    setInputFile(document.getElementById("input-file"));
  };
  return (
    <div>
      <div
        className="container-fluid backgroundcolor     overflow-hidden  w-100  h-auto "
        id="Prices"
      >
        <div className="mt-4">
          <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog ">
              <div class="modal-content">
                <div class="modal-header">
               
                  <button
                    type="button"
                    class="btn-close btn-close-white "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                <h5 class="modal-title text-center texttsize">GET QUOTE</h5>
                  <div
                    className="col   text-center  rounded"
                    data-aos="fade-left   "
                  >
                    <span class="sq"></span>

                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className=" form-horizontal p-4 shadow"
                    >
                      <div className="row  ">
                        <div className=" col-lg-6 col-xl-6  col-sm-6 my-3 form-group ">
                          <input
                            type="text"
                            className="form-control bordersize "
                            placeholder="Your name"
                            name="YOUR_NAME"
                            {...register("YOUR_NAME", { required: true })}
                          />
                          {errors.YOUR_NAME && (
                            <span className="star">This field is required</span>
                          )}
                        </div>
                        <div className="col-lg-6 col-xl-6  col-sm-6 my-3 form-group">
                          <input
                            type="email"
                            className="form-control bordersize"
                            placeholder="You@youremail.com"
                            name="YOUR_EMAIL"
                            {...register("YOUR_EMAIL", { required: true })}
                          />
                          {errors.YOUR_EMAIL && (
                            <span className="star">This field is required</span>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 col-xl-6  col-sm-6 my-3 form-group">
                          <input
                            type="text"
                            className="form-control bordersize"
                            id="inputAddress"
                            placeholder="Your Phone Number"
                            name="PHONE_NUMBER"
                            {...register("PHONE_NUMBER", { required: true })}
                          />
                          {errors.PHONE_NUMBER && (
                            <span className="star">This field is required</span>
                          )}
                        </div>
                        <div className="col-lg-6 col-xl-6  col-sm-6  my-3 form-group">
                          <input
                            type="text"
                            className="form-control bordersize"
                            id="inputAddress2"
                            placeholder="Post code"
                            name="POST_CODE"
                            {...register("POST_CODE", { required: true })}
                          />
                          {errors.POST_CODE && (
                            <span className="star">This field is required</span>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control bordersize"
                          id="exampleFormControlTextarea1"
                          placeholder="Your Message"
                          rows="4"
                          name="MESSAGE"
                          {...register("MESSAGE", { required: true })}
                        />
                        {errors.MESSAGE && (
                          <span className="star">This field is required</span>
                        )}
                      </div>

                      {/* ======================================button-====================================== */}
                      <div className="row d-flex   justify-content-between ">
                        <div className=" col-lg-6 col-md-6  col d-md-block  d-sm-block col-sm-12 d-sm-block ">
                          <input
                            id="input-file"
                            className="d-none"
                            type="file"
                            name="UPLOAD_FILE"
                            {...register("UPLOAD_FILE", { required: true })}
                          />

                          <p
                            onClick={handleUpload}
                            className="btpp   d-md-block  d-sm-block   bttonb btn btn-outline-success  btn-lg"
                          >
                            Upload a file
                          </p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <button
                            onClick={notify}
                            type="submit"
                            class="btpp   bttonb btn btn-success btn-lg d-md-block  d-sm-block "
                          >
                            Send details
                          </button>
                        </div>
                        <ToastContainer />
                      </div>
                    </form>
                  </div>
                </div>
                <div class="modal-footer">
                  {/* <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button> */}
                  {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                </div>
              </div>
            </div>
          </div>
          {/*  <div className="row  flexd  text-white">
            <div className="col col-lg-4 col-md-12 col-sm-12 mt-5"  data-aos="fade-right">
              <div class=" " >
                <p class=" paraprice">OUR</p>
                <h1 className="text-light">
                Pricing
                <br />
                <span
                  className="typeWriter typingFont ChangeColorTyping"
                  data-checkVisible="true"
                  data-end="6000"
                  data-speed="1"
                  data-text='["Estimates"]'
                ></span>
              </h1>
           
                <p class="text-white">
                  Below you can find an estimate pricing for our services.
                  <br />
                  Call us for a free, bespoke quote.
                </p>
              </div>
              <div></div>
            </div>
            <div className="col  mt-5 d-flex justify-content-end col-lg-4 col-md-12 col-sm-12 secondcardm  secondcardmx"  data-aos="fade-up-m">
              <div class="card flexd pricecard">
                <div class="card-body">
                  <h4 class="textprice " style={{fontSize: '20px' ,marginBottom:'-8px'}}>Starter</h4>
                  <br />

                  <div class="pricing_price">
                   <h3 class="text-white " style={{fontSize: '38px',fontWeight:'700'}}>£50</h3>
             
                    <br />

                    <p class="text-white">
                      Package Information <br /> Here
                    </p>

                    <p class="text-white">Minimum weight</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 mt-5 secondcardm "  data-aos="fade-left">
              <div class="card pricecard  ">
                <div class="card-body  text-white">
                  <h4 class="textprice " style={{fontSize: '20px'}}>1/3 Van-4 Cubic Yards</h4>

                  <div class="pricing_price">
                   <h3 class="text-white " style={{fontSize: '38px',fontWeight:'700'}}>£90 -120</h3>
                    <br />

                    <p class="text-white">
                      Package Information <br /> Here
                    </p>

                    <p className="text-white">Maximum Weight: 250 Kg</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row  ">
            <div className="col-lg-4 col-md-12 col-sm-12 secondcardm mb-5"  data-aos="fade-right">
              <div class="card pricecard">
                <div class="card-body">
                  <h4 class="textprice" style={{fontSize: '20px'}}>1/2 Van - 6 Cubic Yards</h4>

                  <div class="pricing_price">
                   <h3 class="text-white" style={{fontSize: '38px',fontWeight:'700'}}>£150-180</h3>
                    <br />

                    <p class="text-white">
                      Package Information <br /> Here
                    </p>
                    <p className="text-white"> Maximum Weight: 550 Kg</p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-12 col-sm-12 secondcardm secondcardmx d-flex justify-content-end mb-5 "  data-aos="fade-up">
              <div class="card pricecard cardfirst ">
                <div class="card-body">
                  <h4 class="textprice" style={{fontSize: '20px'}}>3/4 Van - 8 Cubic Yards</h4>

                  <div class="pricing_price">
                   <h3 class="text-white" style={{fontSize: '38px',fontWeight:'700'}}>£190-230</h3>
                    <br />

                    <p class="text-white">
                      Package Information <br /> Here
                    </p>
                    <p className="text-white"> Maximum Weight: 800 Kg</p>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-12 col-sm-12 secondcardm"  data-aos="fade-left">
              <div class="card  pricecard cardsecond ">
                <div class="card-body">
                  <h4 class="textprice " style={{fontSize: '20px'}}>Full Van - 10 Cubic Yards</h4>

                  <div class="pricing_price">
                   <h3 class="text-white" style={{fontSize: '38px',fontWeight:'700'}}>£240-280</h3>
                    <br />

                    <p class="text-white">
                      Package Information <br /> Here
                    </p>
                    <p className="text-white"> Maximum Weight: 1000 Kg</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
         </div>
          </div>    */}

          <div className=" row container-fluid  ">
            <div className="col mt-3 mb-3 text-center">
              <div class="sub-title ChangeColorTyping">
                <h1 style={{ padding: "28px" }}>
                  Estimated cost for waste disposal.
                  <br /> How much does rubbish
                  <span
                    class="typeWriter typingFont ChangeColorTyping"
                    data-checkVisible="true"
                    data-end="6000"
                    data-speed="1"
                    data-text='[" REMOVAL COST?"]'
                  ></span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row   mt-4 mb-4 rowmargin">
            <div className="col-lg-3 col-md-6 col-sm-12 text-center mb-4  " data-aos="fade-left">
              <h5 className=""> SINGLE ITEM</h5> 
              <h6 className="h6font">£ TBC</h6>
              <p className="tablenm">
                <p className="fw-bold "> PRICE INCLUDES</p>
              </p>
              <div className="text-align-center">
                <p>
                  <FiSettings /> &nbsp; LABOUR: 5 MIN
                </p>
                {/* <p style={{ marginLeft: "2px" }}>
                  <IoMdCube /> &nbsp; CUBIC YARDS: 1
                </p> */}
                <div>
                  <p style={{ marginLeft: "18px" }}>
                    <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 50 KG
                  </p>
                  {/* <p style={{ marginLeft: "21px" }}>
                    <GiSwapBag /> &nbsp; EQUALS TO: 5 BAGS
                  </p> */}
                </div>

                <button
                  type="button"
                  class="btn btn-success"
                  style={{ padding: " 0.375rem 2.75rem", marginLeft: "23px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                GET QUOTE
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 text-center mb-4" data-aos="fade-center">
              <h5 className=""> MINIMUM LOAD</h5> 
              <h6 className="h6font">£ TBC</h6>
              <p className="tablenm">
                <p className=" fw-bold"> PRICE INCLUDES</p>
              </p>
              <div className="text-align-center">
                <p style={{ marginLeft: "-5px" }}>
                  <FiSettings /> &nbsp; LABOUR: 10 MIN
                </p>
                {/* <p>
                  <IoMdCube /> &nbsp; CUBIC YARDS: 1.5
                </p> */}

                <p style={{ marginLeft: "29px" }}>
                  <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 100-150 KG
                </p>
                {/* <p style={{ marginLeft: "10px" }}>
                  <GiSwapBag /> &nbsp; EQUALS TO: 8 BAGS
                </p> */}

                <button
                  type="button"
                  class="btn btn-success"
                  style={{ padding: " 0.375rem 2.75rem", marginLeft: "23px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                GET QUOTE
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 text-center mb-4" data-aos="fade-right">
              <h5 className=""> 1/4 LOAD</h5> 
              <h6 className="h6font">£ TBC</h6>
              <p className="tablenm">
                <p className="fw-bold"> PRICE INCLUDES</p>
              </p>
              <div className="text-align-center">
                <p style={{ marginLeft: "-5px" }}>
                  <FiSettings /> &nbsp; LABOUR: 20 MIN
                </p>
                {/* <p style={{ marginLeft: "2px" }}>
                  <IoMdCube /> &nbsp; CUBIC YARDS: 3.5
                </p> */}
                <div>
                  <p style={{ marginLeft: "32px" }}>
                    <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 200-250 KG
                  </p>
                  {/* <p style={{ marginLeft: "16px" }}>
                    <GiSwapBag /> &nbsp; EQUALS TO: 20 BAGS
                  </p> */}
                </div>

                <button
                  type="button"
                  class="btn btn-success"
                  style={{ padding: " 0.375rem 2.75rem", marginLeft: "23px" }}
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                GET QUOTE
                </button>
              </div>
            </div>
          </div>
          {/* <div className="col mt-3">
            <table class="table table-hover">
              <thead>
                <tr className="fw-bold text-center ">
                  <th className="fw-bold" scope="col">
                    1/2 LOAD
                  </th>
                  <th className="fw-bold" scope="col">
                    3/4 LOAD
                  </th>
                  <th className="fw-bold" scope="col">
                    FULL LOAD
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className=" tablen text-center">
                  <td>£200</td>
                  <td>£260</td>

                  <td>£330</td>
                </tr>
              </tbody>
            </table>
          </div> */}
          {/* <div className="col-12">
            <table class="table table-hover">
              <thead className="text-center">
                <tr className="fw-bold ">
                  <th className="fw-bold tablen" scope="col">
                    PRICE INCLUDE
                  </th>
                  <th className="fw-bold tablen" scope="col">
                    PRICE INCLUDE
                  </th>
                  <th className="fw-bold tablen" scope="col">
                    PRICE INCLUDD
                  </th>
                </tr>
              </thead>
              <tbody className=" ">
                <tr >
                  <td>
                 
                    <FiSettings /> &nbsp; LABOUR: 40 MIN
                  </td>
                  <td>
                    
                    <FiSettings /> &nbsp; LABOUR: 50 MIN
                  </td>

                  <td>
                    
                    <FiSettings /> &nbsp; LABOUR: 60 MIN
                  </td>
                </tr>
                <tr>
                  <td>
                    <IoMdCube /> &nbsp; CUBIC YARDS: 7
                  </td>
                  <td>
                    <IoMdCube /> &nbsp; CUBIC YARDS: 9
                  </td>

                  <td>
                    <IoMdCube /> &nbsp; CUBIC YARDS: 12
                  </td>
                </tr>
                <tr>
                  <td>
                    <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 500-600 KG
                  </td>
                  <td>
                    <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 700-800 KG
                  </td>

                  <td>
                    <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 900-1100 KG
                  </td>
                </tr>
                <tr>
                  <td>
                    <GiSwapBag /> &nbsp; EQUALS TO: 40 BAGS
                  </td>
                  <td>
                    <GiSwapBag /> &nbsp; EQUALS TO: 60 BAGS
                  </td>

                  <td>
                    <GiSwapBag /> &nbsp; EQUALS TO: 80 BAGS
                  </td>
                </tr>
              </tbody>
            </table>
             </div>  */}

               {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Launch demo modal
                  </button> */}
  
            {/* /*==================================================================================================== */}

             <div
            className="row  mt-4 mb-4 rowcard2 "
           
            >
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" data-aos="fade-left">
              <h5 style={{marginLeft:'30px'}}> 1/3 LOAD</h5>
              

              <h6 className="h6font" style={{marginLeft:'55px'}}>£ TBC</h6>
              <p className="tablenn">
                <p className=" fw-bold"> PRICE INCLUDES</p>
              </p>
              <p>
                <FiSettings /> &nbsp; LABOUR: 30 MIN
              </p>
              {/* <p>
                <IoMdCube /> &nbsp; CUBIC YARDS: 5.25
              </p> */}
              <p>
                <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 300-400 KG
              </p>
              {/* <p>
                <GiSwapBag /> &nbsp; EQUALS TO: 30 BAGS
              </p> */}

              <button
                type="button"
                class="btn btn-success"
                style={{ padding: " 0.375rem 2.75rem" }}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
              GET QUOTE
              </button>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" data-aos="fade-center">
              <h5 style={{marginLeft:'37px'}}> 1/2 LOAD</h5>
              
              <h6 className="h6font" style={{marginLeft:'55px'}}>£ TBC</h6>
              <p className="tablenn">
                <p className=" fw-bold"> PRICE INCLUDES</p>
              </p>
              <p>
                <FiSettings /> &nbsp; LABOUR: 40 MIN
              </p>
              {/* <p>
                {" "}
                <IoMdCube /> &nbsp; CUBIC YARDS: 7
              </p> */}
              <p>
                <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 500-600 KG
              </p>
              {/* <p>
                <GiSwapBag /> &nbsp; EQUALS TO: 40 BAGS
              </p> */}

              <button
                type="button"
                class="btn btn-success"
                style={{ padding: " 0.375rem 2.75rem" }}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
              GET QUOTE
              </button>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" data-aos="fade-center">
              <h5 className=""  style={{marginLeft:'37px'}}> 3/4 LOAD</h5>
              
              <h6 className="h6font " style={{marginLeft:'55px'}}>£ TBC</h6>
              <p className="tablenn">
                <p className=" fw-bold"> PRICE INCLUDES</p>
              </p>
              <p>
                <FiSettings /> &nbsp; LABOUR: 50 MIN
              </p>
              {/* <p>
                <IoMdCube /> &nbsp; CUBIC YARDS: 9
              </p> */}
              <p>
                <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 700-800 KG
              </p>
              {/* <p>
                <GiSwapBag /> &nbsp; EQUALS TO: 60 BAGS
              </p> */}

              <button
                type="button"
                class="btn btn-success"
                style={{ padding: " 0.375rem 2.75rem" }}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
              GET QUOTE
              </button>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 mb-4" data-aos="fade-right">
              <h5 className=""  style={{marginLeft:'30px'}}> FULL LOAD</h5>
              
              <h6  style={{marginLeft:'55px'}}>£ TBC</h6>
              <p className="tablenn">
                <p className=" fw-bold"> PRICE INCLUDES</p>
              </p>
              <p>
                <FiSettings /> &nbsp; LABOUR: 60 MIN
              </p>
              {/* <p>
                <IoMdCube /> &nbsp; CUBIC YARDS: 12
              </p> */}
              <p>
                <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 900-1100 KG
              </p>
              {/* <p>
                <GiSwapBag /> &nbsp; EQUALS TO: 80 BAGS
              </p> */}

              <button
                type="button"
                class="btn btn-success"
                style={{ padding: " 0.375rem 2.75rem" }}
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
              GET QUOTE
              </button>
             </div>
             </div>

              {/* ==================================================================================================== */}
               {/* <div className="col mt-3">
               <table class="table table-hover">
              <thead className="">
                  <tr className="fw-bold  ">
                  <th className="fw-bold" scope="col">
                    SINGLE ITEM
                  </th>
                  <th className="fw-bold" scope="col">
                    MINIMUM LOAD
                  </th>
                  <th className="fw-bold" scope="col">
                    1/4 LOAD
                  </th>
                  <th className="fw-bold" scope="col">
                    1/3 LOAD
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className=" tablen ">
                  <td>£60</td>
                  <td>£90</td>
                  <td>£110</td>
                  <td>£150</td>
                </tr>
              </tbody>
                 </table>
              </div>
              <div class="row mt-5 mb-5">
               <div className="col-12 d-flex justfy-content-center margintop1">
              <table class="table table-hover">
                <thead className="">
                  <tr className="fw-bold   ">
                    <th className="fw-bold tablen " scope="col">
                      PRICE INCLUDE
                    </th>
                    <th className="fw-bold tablen " scope="col">
                      PRICE INCLUDE
                    </th>
                    <th className="fw-bold tablen " scope="col">
                      PRICE INCLUDE
                    </th>
                    <th className="fw-bold tablen" scope="col">
                      PRICE INCLUDE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <FiSettings /> &nbsp; LABOUR: 5 MIN
                    </td>
                    <td>
                      <FiSettings /> &nbsp; LABOUR: 10 MIN
                    </td>
                    <td>
                      <FiSettings /> &nbsp; LABOUR: 20 MIN
                    </td>
                    <td>
                      <FiSettings /> &nbsp; LABOUR: 30 MIN
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <IoMdCube /> &nbsp; CUBIC YARDS: 1
                    </td>
                    <td>
                      <IoMdCube /> &nbsp; CUBIC YARDS: 1.5
                    </td>
                    <td>
                      <IoMdCube /> &nbsp; CUBIC YARDS: 3.5
                    </td>
                    <td>
                      <IoMdCube /> &nbsp; CUBIC YARDS: 5.25
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 50 KG
                    </td>
                    <td>
                      <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 100-150 KG
                    </td>
                    <td>
                      <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 200-250 KG
                    </td>
                    <td>
                      <RiDeleteBin5Fill /> &nbsp; MAX WEIGHT: 300-400 KG
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <GiSwapBag /> &nbsp; EQUALS TO: 5 BAGS
                    </td>
                    <td>
                      <GiSwapBag /> &nbsp; EQUALS TO: 8 BAGS
                    </td>
                    <td>
                      <GiSwapBag /> &nbsp; EQUALS TO: 20 BAGS
                    </td>
                    <td>
                      <GiSwapBag /> &nbsp; EQUALS TO: 30 BAGS
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Prices;
