/* eslint-disable no-unused-vars */
import React from 'react'


import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function Scrollmap() {
  
  
 
    
      return (
        <div className='container position-relative'>
        <TransformWrapper
           initialScale={1}
          initialPositionX={200}
          initialPositionY={100}
        >
          {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
            <React.Fragment>
              <div className="tools">
              
                <button className='btn btn-light btn-sm  rounded-3 fs-5 fw-bold  m-0' data-toggle="tooltip" data-placement="right" title="zoom" onClick={() => zoomIn()}>+</button>
                <button className='btn btn-light btn-sm rounded-3  fs-5  fw-bold m-0' onClick={() => zoomOut()}>-</button>
                {/* <button className='btn btn-secondary btn-lg m-1' onClick={() => resetTransform()}>x</button> */}
              </div>
              <TransformComponent >
             
                <img  src="./maplondon.jpg" alt="test" />
               
              </TransformComponent>
            </React.Fragment>
          )}
        </TransformWrapper>
        </div>
      );
    }
  
  



 


 

export default Scrollmap