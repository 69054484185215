/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import Scrollmap from "./Scrollmap";


const Boroughs = () => {
 
  return (
    <div>
      <div className="container p-0 mt-5 " id="Boroughs">
        <div className=" row ">
          <div
            className="col-lg-4 col-xl-4 col-md-12 col-sm-10 "
            data-aos="fade-right"
          >
            <div class=" mt-5 " style={{ marginLeft: "20px" }}>
              <p class="">Some of the</p>
              <h1>
                Areas We
                <br />
                <span
                  class="typeWriter typingFont"
                  data-checkVisible="true"
                  data-end="6000"
                  data-speed="1"
                  data-text='["Cover."]'
                ></span>
              </h1>
            </div>
          </div>
          <div className=" g-0  col-lg-4 col-xl-4 col-md-12 col-sm-12  ">
            <div class="  card_main">
              <div class="l-box btns">
                <div className="main  ">
                  <div className="sub ">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>

                  <div className="sub1">
                    <h3>London</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 pr-1  g-0 ">
            <div class=" card_main">
              <div class="l-box btns">
                <div className="main ">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Hertfordshire</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row offset-1 " style={{ marginBottom: "20px" }}>
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
            <div class=" card_main margincard">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Middlesex</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 g-0" style={{ marginLeft:"-11px"  }}>
            <div class=" card_main">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Essex</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 g-0">
            <div class=" card_main">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Bedfordshire </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row offset-1 justify-content-center" style={{ marginBottom: "20px" , marginRight:"20px" }}>
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
            <div class=" card_main margincard">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Kent</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 g-0">
            <div class=" card_main">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Essex</h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 g-0">
            <div class=" card_main">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Kent</h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        {/* <div
          className="row offset-1 d-flex justify-content-center"
          style={{ marginBottom: "20px" }}
        >
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
            <div class=" card_main margincard">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Bedfordshire</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
      <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 g-0">
            <div class=" card_main">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Kingston upon Thames</h3>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 g-0">
            <div class=" card_main">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Maidstone</h3>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
      <div className="row offset-1" style={{ marginBottom: "20px" }}>
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
            <div class=" card_main margincard">
              <div class="l-box btns">
                <div className="main btn-content-wrapper">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Colchester</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 g-0">
            <div class=" card_main">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Chelmsford</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 g-0">
            <div class=" card_main">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Stevenage </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="row offset-1" style={{ marginBottom: "20px" }}>
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12">
            <div class=" card_main margincard">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>City of Westminster</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 g-0">
            <div class=" card_main">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Lewisham</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12 g-0">
            <div class=" card_main">
              <div class="l-box btns">
                <div className="main">
                  <div className="sub">
                    <i class="fa-solid fa-square-check fa-2xl fa2xl solidfas"></i>
                  </div>
                  <div className="sub1">
                    <h3>Lewisham</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <section id="find" style={{ marginTop: "4%" }}>
          <div class=" container ">
               <Scrollmap/>
            
          </div>
        </section>
        {/* <div className="container" style={{ marginTop: "10%" }}>
      

          <div class="row position-relative  d-flex justify-content-center justify-content-around gx-4 gy-4 mb-5 mt-5 ">
            <div
              class="col-sm-12 col-lg-5 col-xl-5 col-md-12  "
              style={{ marginTop: "75px" }}
            >
              <div className="mb-4">
                <div class="card rowmodel">
                  <div class="card-body">
                    <h5 class="card-title">EXTRA 10MIN LABOUR</h5>
                    <p class="card-text">
                      If we exceed the loading time we charge
                    </p>
                  </div>
                  <div
                    className="position-absolute  d-flex justify-content-center "
                    id="hexagon"
                  >
                    <span className="colroted"> £10 </span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <div class="card rowmodel">
                  <div class="card-body">
                    <h5 class="card-title">COMMERCIAL FRIDGE</h5>
                    <p class="card-text">Due to how it is disposed of</p>
                  </div>
                  <div
                    className="position-absolute  d-flex justify-content-center "
                    id="hexagon"
                  >
                    {" "}
                    <span className="colroted"> £100 </span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <div class="card rowmodel">
                  <div class="card-body">
                    <h5 class="card-title">PER FLUORESCENT TUBE</h5>
                    <p class="card-text">Due to how it is disposed of</p>
                  </div>
                  <div
                    className="position-absolute  d-flex justify-content-center "
                    id="hexagon"
                  >
                    {" "}
                    <span className="colroted"> £1 </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-lg-5 col-xl-5 col-md-12  ">
              <div className="mb-4">
                <div class="card rowmodel">
                  <div class="card-body">
                    <h5 class="card-title">FRIDGE</h5>
                    <p class="card-text">Due to how it is disposed of</p>
                  </div>
                  <div
                    className="position-absolute  d-flex justify-content-center "
                    id="hexagon"
                  >
                    {" "}
                    <span className="colroted"> £15 </span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <div class="card rowmodel">
                  <div class="card-body">
                    <h5 class="card-title">PAINT PER LITER</h5>
                    <p class="card-text">Due to how it is disposed of </p>
                  </div>
                  <div
                    className="position-absolute  d-flex justify-content-center "
                    id="hexagon"
                  >
                    {" "}
                    <span className="colroted"> £30 </span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <div class="card rowmodel">
                  <div class="card-body">
                    <h5 class="card-title">PAINT PER LITER</h5>
                    <p class="card-text">Due to how it is disposed of </p>
                  </div>
                  <div
                    className="position-absolute  d-flex justify-content-center "
                    id="hexagon"
                  >
                    {" "}
                    <span className="colroted"> £3 </span>
                  </div>
                </div>
              </div>

              <div className="mb-4">
                <div class="card rowmodel">
                  <div class="card-body">
                    <h5 class="card-title">MATTRESS</h5>
                    <p class="card-text">Due to how it is disposed of </p>
                  </div>
                  <div
                    className="position-absolute  d-flex justify-content-center "
                    id="hexagon"
                  >
                    {" "}
                    <span className="colroted"> £20 </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Boroughs;
