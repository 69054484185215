import React, { useState } from "react";
import { Slider } from 'antd';

const Priceestimate = () => {
  const  [ showState, setShowState] =  useState({
    forVal:'£120',
    fillVal:'1/4 Van'
  })
  console.log('showState', showState)
  let forValue = ['£240 - £280', '£190 - £230', '£150 - £180', '£90 - £120', '£50']
  let fillupValue = ['Full Van', '3/4 Van', '1/2 Van', '1/3 Van', 'Starter']

  const handleClick=(value)=>{
   console.log('value', value)
      if(value >= 0 && value < 10) setShowState({forVal:'£120', fillVal:'1/4 van'})
      if (value >= 10 && value < 30) setShowState({forVal:'£120', fillVal:'1/4 van'});
      if (value >= 30 && value < 60) setShowState({forVal:'£190', fillVal:'1/2 van'});
      if (value >= 60 && value < 80) setShowState({forVal:'£280', fillVal:'3/4 van'});
      if (value > 80 && value < 100) setShowState({forVal:'£330', fillVal:'FULL van'});
  }
  return (
    <div>
      <div className="container-fluid  position-relative" style={{marginTop:'10%'}}>
        <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1574414820998 vc_row-has-fill vc_row-o-equal-height vc_row-o-content-bottom vc_row-flex">
          <div className="container mt-5">
            <div className="row   d-flex justify-content-between  " data-aos="fade-up">
             
              <div className="col-lg-6 col-md-6 col-sm-12">
                <p class="subtitle">Quick</p>
                <h1>
                Pricing
                &nbsp; 
                <span 
                  class="typeWriter typingFont"
                  data-checkVisible="true"
                  data-end="6000"
                  data-speed="1"
                  data-text='["Estimate"]'
                ></span>
              </h1>
  
               {/*     <h2 class="title subtitle-top">Pricing Estimate </h2>*/}
                <p  className='text-withe mt-5'>
                  Use the slider to get a brief idea of our prices, better call
                  for a quick, bespoke quote.
                </p>

                <div class="s-wrap  ">
                  For
                  <div class="sl p-sl" id="price-b wordCarousel">
                    <h6 style={{marginLeft:'4px',textAlign:'center',fontSize:'23px'}}> {showState.forVal} </h6>
                  </div>
                  , I can fill up
                  <div class="sl s-sl" id="size-b wordCarousel">
                    <h6  style={{marginLeft:'4px',textAlign:'center' ,fontSize:'23px'}}> {showState.fillVal}</h6>
                  </div>
                  .
                 </div>
                 <div class="vc_empty_space" style={{ height: "20px" }}>
                  <span class="vc_empty_space_inner"></span>
                </div>
                <div
                  class="ohio-button-sc btn-wrap text-left mt-2" 
               
                >
                  {/* <div className="btn btn-success Navigate_button p-0 " >
                    <i className="fa-solid fa-left-long text-light fasolid" onClick={()=>handleClick('next')}></i>
                    <span class="text-light text-center " style={{marginRight:'4px'}}>Navigate</span>
                    <i class="fa-solid fa-right-long text-light" onClick={()=>handleClick('back')}></i>
                  </div> */}

                  {/* <Slider marks={marks} step={null} defaultValue={[0]}  /> */}
                 <div className=' mt-2  justify-content-center pricecss' >
                  <Slider step={5}  defaultValue={[25]} trackStyle={{backgroundColor:'green', }} onChange={(value)=>handleClick(value)} tipFormatter={null} />
                 </div>
                </div>
                <div
                  class="vc_empty_space  clb__section_spacer vc_hidden-xs"
                  style={{ height: "90px" }}
                >
                  <span class="vc_empty_space_inner"></span>
                </div>
              </div>

              <div className="col-md-6 col-lg-6 col-sm-12 col-lg-6">
                <img
                  width="1000"
                  height="auto"
                  src="aboutp.png" alt="logo"
                  class="r-s-img vc_single_image-img attachment-full"
                />
              </div>
      
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Priceestimate;
