import React from "react";

const About = () => {
  return (
    <div>
      <div className="container mb-4  wrapper-intro " id="About">
        <div className="row mt-5 d-flex justify-content-between ">
          <div
            className="col-lg-7  col-xl-7 col-md-12 col-sm-12 d-flex flex-column justify-content-center"
            data-aos="zoom-in-right"
          >
            <p class="subtitle"> GET TO KNOW US </p>
            <h1>
              <span
                class="typeWriter typingFont"
                data-checkVisible="true"
                data-end="6000"
                data-speed="1"
                data-text='["About us."]'
              ></span>
            </h1>

            {/*  <h2 class="title subtitle-top">About us. </h2>*/}
            <p>
              Enviro (London) Ltd. is a comprehensive waste removal company with
              more than 10 years of experience in the industry, we remove all
              types of non hazardous waste which can be a more convenient
              alternative to a skip. Offering a wide range of services in the
              greenest way possible by collecting and recycling from single
              items to full home or office unwanted waste, garden, builders &
              fly tipped waste, collecting all kinds of items and recycling
              approximately 89% of the items we remove using fully licensed
              waste transfer & recycling stations, to ensure we come up with a
              completely eco-friendly waste clearance service.
              <br /> <br /> We are a friendly compliant fully insured & licensed
              waste carrier who will ensure you that your unwanted waste is in
              good hands giving you peace of mind. We are competitively priced
              and can work out cheaper than a skip for most jobs. We currently
              cover all of London and surrounding areas.
            </p>
          </div>
          ̥
          <div
            className="col-lg-5 col-xl-5 col-sm-12 col-md-12"
            data-aos="zoom-in-right "
          >
            <div class="">
              <img className="" src="garden1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
