import React, { useState } from "react";



const  NavBar1=()=> {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <>

      <nav className="navbar">
      <div className="container">
        <div className="nav-container">
          {/* <a exact href="/" >
            CodeBucks
            <i className="fas fa-code"></i>
          </a> */}
          <div
               className="nav-logo"
                style={{
                  width: "20%",

                  marginTop: "",
                  marginLeft: "",
                }}
              >
              <a href="/Home" > <img
                  className="logo"
                  src="logoa.png"
                  width="50%"
                  height="auto"
                  alt="show"
                   style={{verticalAlign: 'inherit'}}
                /> </a>
              </div>

          <ul className={  click ? "nav-menu active" : "nav-menu"} >
            <li className="nav-item">
              <a
                exact
                href="#home"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                exact
                href="#About"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </a>
            </li>
            <li className="nav-item">
              <a
                exact
                href="#Service"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
              Service
              </a>
            </li>
            <li className="nav-item">
              <a
                exact
                href="#Prices"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Prices
              </a>
            </li>
            <li className="nav-item">
              <a
                exact
                href="#Boroughs"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
              Areas
              </a>
            </li>
            <li>
            <div className="buttonmtop">
                <div className="col d-flex justify-content-center " style={{marginTop:'-21px'}}>
                  <p className="text-center mb-0  text-bold">
                    CALL US
                  </p>
                </div>

                <button class=" btn btn-success mb-0" type="submit">
                  <a
                    className="text-light"
                    style={{ textDecoration: "none" }}
                    href="tel:01279 456695"
                  >
                   01279 456695
                  </a>
                </button>
                </div>
              </li>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times fa-3x" : "fas fa-bars fa-3x"}></i>
          </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar1;